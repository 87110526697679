<template>
  <div class="my-course">
    <div class="course-row">
      <article class="course-card">
        <div class="course-image">
          <a class="image-link"></a>
          <figure class="image-figure">
            <img v-if="params.thumb" :src="getThumbUrl(params.thumb)" alt="">
            <img v-else-if="params.mstThumb" :src="getThumbUrl(params.mstThumb)" alt="">
            <CourseThumb v-else :num="params.crseMstSn"/>
          </figure>
        </div>
        <div class="course-content">
          <div class="content-header">
            <h5 class="title" @click="learnContent()">{{ params.crseNm }}</h5>
          </div>
          <div class="content-meta">
            <span class="text">{{ params.orgnzNm }}</span>
            <span class="text">{{ timestampToDateFormat(params.bgngDt, 'yyyy.MM.dd.w') }} - {{ timestampToDateFormat(params.endDt, 'yyyy.MM.dd.w') }}</span>
<!--            <span v-if="params.crseMlg > 0" class="text">마일리지 {{ params.crseMlg }}</span>-->
          </div>
          <div class="content-meta">
            <span v-if="params.splCrseTyCdDcd === '2066013' || params.splCrseTyCdDcd === '2066014'" class="text" v-html="finishText"></span>
          </div>
        </div>
<!--        <div class="course-icons">-->
<!--          <div class="icons">-->
<!--            <i class="icon-course-collective" title="집합연수"></i>-->
<!--            <i class="icon-course-cyber" title="사이버"></i>-->
<!--            <i class="icon-course-book" title="교재"></i>-->
<!--          </div>-->
<!--        </div>-->
      </article>
    </div>
  </div>
</template>

<script>

import CourseThumb from '@/components/apply/CourseThumb';
import {learnInfoSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'LearnInfo',
  components: {CourseThumb},
  methods:{
    learnContent(){
      this.$router.push('/learn/'+this.params.distCrseSn+'/index');
    }
  },
  props: {
    learns: Array,
    params: Object,
  },
  setup: learnInfoSetup
};

</script>
