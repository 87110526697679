<template>
  <div class="content-section section-status mt-5">
    <div class="section-header">
<!--      <div class="nav">-->
<!--        <button class="kb-nav-link" :class="{ 'is-active': tab === 'today' }" @click="selectTab('today')"><span class="title">오늘 학습만 보기</span></button>-->
<!--        <button class="kb-nav-link" :class="{ 'is-active': tab === 'all' }" @click="selectTab('all')"><span class="title">전체 학습목차 보기</span></button>-->
<!--      </div>-->
<!--      <div class="header-sub"><span class="subtext text-gold">{{ currentDateStr }}</span></div>-->
    </div>
    <!-- tab-panel#tab01 -->
    <!-- [!DEV] tab-panel is-active 탭 활성화 -->
    <div class="tab-panel is-active">
      <div class="learning-status-wrap">
        <div v-if="renderCount > 0" class="kb-table kb-table-bordered learning-tablelist">
          <table>
            <colgroup>
              <col style="width:auto" />
<!--              <col style="width:380px" />-->
              <col style="width:300px" />
              <col style="width:100px" />
              <col style="width:250px" />
              <col style="width:162px" />
            </colgroup>
            <tbody>
            <template v-for="(item, index) in items" :key="index">
              <tr>
                <td>
                  <div class="item-content">
                    <div class="content-symbol"><i :class="getSymbolType(item.lrnObjTyCd)"></i></div>
                    <div class="content-details">
                      <h5 class="content-title">
                        <a v-if="item.splCrseTyCdDcd === '2066015'" href="javascript:" class="title-link">{{item.chapNm ? item.chapNm : '' }}</a>
                        <a v-else href="javascript:" class="title-link">{{ item.objNm }}</a>
                      </h5>
                      <p class="content-text"><span class="text">{{ item.lrnObjTyNm === 'MP4' ? '동영상' : item.lrnObjTyNm}}</span></p>
                    </div>
                  </div>
                </td>
<!--                <td>-->
<!--                  <div class="item-date">-->
<!--                    <span v-if="item.objBgngDt && item.objEndDt" class="text">{{ getObjectDt(item, 'Bgng')}} ~ {{ getObjectDt(item, 'End') }}</span>-->
<!--                    <span v-else class="text">-</span>-->
<!--                  </div>-->
<!--                </td>-->
                <td>
                  <div class="item-type">
                    <span v-if="item.splCrseTyCdDcd === '2066015'"  class="text">{{ item.objNm }}</span>
                    <span v-else class="text">{{ item.chapNm ? item.chapNm : ''}}</span>
                  </div>
                </td>
                <td><div class="item-teacher"><span class="text">{{getTutors(item)}}</span></div></td>
                <!-- 출석 / 결석 / 응시전 / 응시완료 / 참여전 / 제출전  -->
                <td><div class="item-status"><span class="text" v-html="getScoreUnit(item, params.coldCallUseYn)"></span></div></td>
                <td>
                  <div class="item-actions">
<!--                    <a v-if="item.fnshYn === 'Y'" href="javascript:" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'Y')"><strong class="text">학습하기</strong></a>-->
                    <a v-if="canDo(item, currentDate)" href="javascript:" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'N')"><strong class="text">학습하기</strong></a>
                    <a v-else href="javascript:" class="kb-btn kb-btn-primary" disabled><strong class="text">학습하기</strong></a>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {learnRegularIndexSetup} from '@/assets/js/modules/learn/learn-regular-setup';

export default {
  name: 'LearnRegularIndex',
  components: {},
  props: {
    params: Object,
    items: Array,
    currentDate: Object
  },
  emits: ['openLearn'],
  setup: learnRegularIndexSetup
};
</script>
