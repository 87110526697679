<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-talentboard-o022">
      <!-- popup > popup-inner -->
      <div ref="mainRef" class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">AFPK/CFP 윤리교육</h3>
          </header>
          <div class="popup-content">
            <!-- table-list-container -->
            <div class="kb-table-list-container">
              <!-- list-top -->
              <div class="list-top">
                <div class="top-column"></div>
                <div class="top-column">
                  <template v-if="isCaptureMode" >
                    <div style="display: flex; flex-direction: column;">
                      <span class="mb-2 fw-bold" style="text-align: right">출력직원: {{session.deptNm}} / {{session.jbgdCd}} / {{session.lrnerNm}}({{session.lrnerId}})</span>
                      <span class="fw-bold" style="text-align: right">출력일시: {{timestampToDateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss')}}</span>
                    </div>
                  </template>
                  <template v-else>
                    <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" style="margin-right: 8px;" @click="captureLearn">
                      <i class="icon-download32"></i>
                      <span class="text">이미지 다운로드</span>
                    </button>

                    <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="printLearn">
                      <i class="icon-print-doc"></i>
                      <span class="text">인쇄하기</span>
                    </button>
                  </template>

                </div>
              </div>

              <div class="kb-table kb-table-bordered-v2 mb-5">
                <table>
                  <tbody>
                    <tr>
                      <th class="cell-border kb-th"><span class="th-title">성명(직원번호)</span></th>
                      <td class="padding-12"><span class="td-text">{{session.lrnerNm}}({{session.lrnerId}})</span></td>

                      <th class="cell-border kb-th"><span class="th-title">소속</span></th>
                      <td class="padding-12"><span class="td-text">{{session.deptNm}}</span></td>

                      <th class="cell-border kb-th"><span class="th-title">직위</span></th>
                      <td class="padding-12"><span class="td-text">{{session.jbgdCd}}</span></td>

                      <th class="cell-border kb-th"><span class="th-title">생년월일</span></th>
                      <td class="padding-12"><span class="td-text">{{getStrWithDash(info.brdt, '-')}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="kb-table kb-table-bordered-v2">
                <table>
                  <colgroup>
                    <col>
                    <col>
                    <col>
                    <col>
                    <col>
                  </colgroup>
                  <thead>
                  <tr>
                    <th class="cell-border"><span class="th-title">과정차수명</span></th>
                    <th class="cell-border"><span class="th-title">학습기간</span></th>
                    <th class="cell-border"><span class="th-title">콘텐츠명</span></th>
                    <th class="cell-border"><span class="th-title">누적 진도율</span></th>
                    <th class="cell-border"><span class="th-title">학습완료여부</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(item, index) in renderItems" :key="index">
                    <tr v-if="item.learns && item.learns.length > 0">
                      <td :rowspan="item.learns.length" class="padding-12"><span class="td-text">{{item.crseNm}}</span></td>
                      <td :rowspan="item.learns.length" class="padding-12"><span class="td-text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></td>
                      <td class="padding-12"><span class="td-text">{{item.learns[0].objNm}}</span></td>
                      <td class="padding-12"><span class="td-text" v-html="getScore(item.learns[0], '2066019')"></span></td>
                      <td class="padding-12">
                        <span class="td-text" v-html="getFinishText(item, item.learns[0], '2066019')"></span>
                      </td>
                    </tr>
                    <template v-for="(learn, idx) in item.learns" :key="`${index}-${idx}`">
                      <tr v-if="idx > 0">
                        <td class="padding-12"><span class="td-text">{{learn.objNm}}</span></td>
                        <td class="padding-12"><span class="td-text" v-html="getScore(learn, '2066019')"></span></td>
                        <td class="padding-12">
                          <span class="td-text" v-html="getFinishText(item, learn, '2066019')"></span>
                        </td>
                      </tr>
                    </template>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>
<script>

import {learnRegularEthicsStatusSetup} from '@/assets/js/modules/learn/learn-regular-status-setup';

export default {
  name: "LearnRegularEthicsStatus",
  components: {},
  props: {
    toggle: Boolean,
    params: Object,
    items: Array
  },
  emits: ['update:toggle'],
  setup: learnRegularEthicsStatusSetup
}
</script>
