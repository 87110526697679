<template>
  <main ref="mainRef" class="kb-main" id="kb-myclass-home">
    <lxp-main-header :show-back="true">
      <template v-slot:inner>
        <template v-if="!digitalStatuses.includes(params.splCrseTyCdDcd) && !starLinkStatuses.includes(params.splCrseTyCdDcd)">
          <button v-if="params.splCrseTyCdDcd" type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="showStatus">
            <i class="icon-data"></i>
            <span class="text">{{ ethicsStatuses.includes(params.splCrseTyCdDcd) ? 'AFPK/CFP 윤리교육 현황' : '판매자격연수 현황' }}</span>
          </button>
        </template>
      </template>
    </lxp-main-header>

    <div v-if="readies.course && readies.object" class="main-content main-component">
      <LearnInfo
          :params="params"
          :learns="objectResults"
      />
      <LearnRegularIndex
          :params="params"
          :items="objectResults"
          :current-date="currentDate"
          @openLearn="openLearn"
      />
    </div>
    <div v-else class="mt-5 mb-5"/>

    <LearnCms
        v-if="windowActive && targetLearnType === 'cms'"
        v-model:active="windowActive"
        :item="targetItem"
        :cms="targetAdded"
        @renewEnd="getLearnObjectResult"
        :memory-limit="7"
        :use-cold-call="params.coldCallUseYn === 'Y'"
        :lrn-psblt-hr-div="params.lrnPsbltHrDiv"
    />
    <!-- 테스트  -->

    <template v-if="statusToggle">
      <LearnRegularEthicsStatus
          v-if="ethicsStatuses.includes(params.splCrseTyCdDcd)"
          v-model:toggle="statusToggle"
          :items="items"
          :params="params"
      />
      <LearnRegularStatus
          v-else
          v-model:toggle="statusToggle"
          :items="items"

      />
    </template>
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import LearnInfo from '@/components/learn/LearnInfo';
import LearnCms from '@/components/learn/window/LearnCms';
import LearnRegularIndex from '@/components/learn/LearnRegularIndex';
import LearnRegularStatus from '@/components/learn/regular/LearnRegularStatus';
import {learnRegularMainSetup} from '@/assets/js/modules/learn/learn-regular-setup';
import LearnRegularEthicsStatus from '@/components/learn/regular/LearnRegularEthicsStatus';

export default {
  name: 'LearnRegularMain',
  components: {
    LearnRegularEthicsStatus,
    LearnRegularStatus,
    LearnRegularIndex,
    LearnCms, LearnInfo, LxpMainHeader},
  setup: learnRegularMainSetup
}
</script>
